import './App.sass';
import React from 'react';
import axios from 'axios';
import * as MUI from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';

export default function App() {

  // Data
  const [users, setUsers] = React.useState([]);
  const [mail, setMail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  // UI
  const [smsCount, setSmsCount] = React.useState(0);
  const [sortConfig, setSortConfig] = React.useState({ key: 'registration_date', direction: 'desc' });

  // ______________________________________________________________________ Functions: UI ___________________________________________________________________

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getNestedProperty = (obj, path) => {
    const pathParts = path.split('.');
    if (pathParts[pathParts.length - 1] === 'length') {
      // Remove the 'length' part from the path
      pathParts.pop();
      // Get the array
      const arr = pathParts.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, obj);
      // Return its length
      return arr ? arr.length : undefined;
    }
    // If the path doesn't end in 'length', just get the property like before
    return pathParts.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, obj);
  };

  const sortedUsers = React.useMemo(() => {
    const sortedArray = [...users];
    if (sortConfig.key) {
      sortedArray.sort((a, b) => {
        const valueA = getNestedProperty(a, sortConfig.key);
        const valueB = getNestedProperty(b, sortConfig.key);

        // Check if values are numbers
        const isNumericComparison = !isNaN(valueA) && !isNaN(valueB);

        if (isNumericComparison) {
          // Numeric comparison
          if (valueA < valueB) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (valueA > valueB) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
        } else {
          // String comparison
          const strA = String(valueA).toLowerCase();
          const strB = String(valueB).toLowerCase();

          if (strA < strB) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (strA > strB) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
        }

        return 0;
      });
    }
    return sortedArray;
  }, [users, sortConfig]);

  const generatePaymentLink = (server, id, mail) => {
    if (server == "prod") {
      navigator.clipboard.writeText("https://buy.stripe.com/5kAbL5aPX2eWfsc3cd" + "?client_reference_id=" + id + "&prefilled_email=" + mail)
    }
    if (server == "test") {
      navigator.clipboard.writeText("https://buy.stripe.com/test_5kAaFS5JNchC53O4gi" + "?client_reference_id=" + id + "&prefilled_email=" + mail)
    }
  }

  // ______________________________________________________________________ Functions: API ___________________________________________________________________

  const authenticate = () => {
    try {
      axios.get('/api/verify')
        .then(res => {
          if (res.status === 200) {
            setIsAuthenticated(true)
          }
        })
        .catch(err => {
          console.info("User is not authenticated.")
        })
    } catch (ex) {

    }
  }

  const login = (e) => {
    e.preventDefault();
    if (mail < 4 || password.length < 6) {
      alert("Bitte überprüfe die E-Mail und Passwortlänge.");
      return
    }
    axios.post('/api/users/login', {
      mail: mail,
      password: password
    })
      .then(function (res) {
        setIsAuthenticated(true)
      })
      .catch(function (error) {
        console.error(error);
      })
  }

  const getAllUsers = () => {
    axios.get('/api/ccp/getAllUsers')
      .then(res => {
        setUsers(res.data)
        console.log(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const deleteUser = (id) => {
    console.log(id)
    axios.delete('/api/ccp/deleteUser/' + id)
      .then(res => {
        console.log(res)
        getAllUsers()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const changeActivation = (id) => {
    console.log(id)
    axios.post('/api/ccp/changeActivation/' + id)
      .then(res => {
        console.log(res)
        getAllUsers()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const changePaymentStatus = (id) => {
    console.log(id)
    axios.post('/api/ccp/changePaymentStatus/' + id)
      .then(res => {
        console.log(res)
        getAllUsers()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const changeBlockedStatus = (id) => {
    console.log(id)
    axios.post('/api/ccp/changeBlockedStatus/' + id)
      .then(res => {
        console.log(res)
        getAllUsers()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const UserMenu = (props) => {

    const [menuAnchorEl, setMenuAnchorEl] = React.useState("");
    const menuOpen = Boolean(menuAnchorEl);

    return (
      <>
        <React.Fragment>

          <span>
            <MUI.IconButton id={"cust-menu-" + props.user?._id} onClick={event => setMenuAnchorEl(event.currentTarget)} >
              <span className="material-icons"
                aria-controls={menuOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? 'true' : undefined}
              >
                more_vert
              </span>
            </MUI.IconButton>
          </span>

          <Menu
            id={"cust-menu-id-" + props.user?._id}
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={() => setMenuAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-menu',
            }}
          >
            <div>
              <>
                <MenuItem
                  onClick={() => setMenuAnchorEl(null) & generatePaymentLink("prod", props.user._id, props.user.mail)}>
                  <span className="material-icons"> payment </span> Stripe-Link generieren <b>(prod)</b>
                </MenuItem>
                <MenuItem
                  onClick={() => setMenuAnchorEl(null) & generatePaymentLink("test", props.user._id, props.user.mail)}>
                  <span className="material-icons"> payment </span> Stripe-Link generieren <b>(test)</b>
                </MenuItem>
                <hr />
                {props.user?.activated === true ?
                  <MenuItem
                    onClick={() => setMenuAnchorEl(null) & changeActivation(props.user._id)}>
                    <span className="material-icons"> cancel </span> User deaktivieren
                  </MenuItem>
                  :
                  <MenuItem
                    onClick={() => setMenuAnchorEl(null) & changeActivation(props.user._id)}>
                    <span className="material-icons"> check </span> User aktivieren
                  </MenuItem>
                }
                {props.user?.blocked === true ?
                  <MenuItem
                    onClick={() => setMenuAnchorEl(null) & changeBlockedStatus(props.user._id)}>
                    <span className="material-icons"> check </span> User entsperren
                  </MenuItem>
                  :
                  <MenuItem
                    onClick={() => setMenuAnchorEl(null) & changeBlockedStatus(props.user._id)}>
                    <span className="material-icons"> cancel </span> User sperren
                  </MenuItem>
                }
                {props.user?.paid === true ?
                  <MenuItem
                    onClick={() => setMenuAnchorEl(null) & changePaymentStatus(props.user._id)}>
                    <span className="material-icons"> cancel </span> Als nicht bezahlt markieren
                  </MenuItem>
                  :
                  <MenuItem
                    onClick={() => setMenuAnchorEl(null) & changePaymentStatus(props.user._id)}>
                    <span className="material-icons"> check </span> Als bezahlt markieren
                  </MenuItem>
                }
                <hr />
                <MenuItem onClick={() => {
                  const confirmation = window.confirm(`Möchtest du diesen Benutzer (${props.user._id}) wirklich löschen?`);
                  if (confirmation) {
                    setMenuAnchorEl(null);
                    deleteUser(props.user._id);
                  }
                }}>
                  <span className="material-icons"> delete </span> User löschen
                </MenuItem>
              </>
            </div>
          </Menu>
        </React.Fragment >
      </>
    )
  }

  // ______________________________________________________________________ State Effects ___________________________________________________________________

  React.useEffect(() => {
    authenticate();
  }, [])

  React.useEffect(() => {
    isAuthenticated && getAllUsers()
  }, [isAuthenticated])

  return (
    <div className="App">
      {!isAuthenticated &&
        <form onSubmit={login}>
          <MUI.TextField value={mail} onChange={(e) => setMail(e.target.value)} inputProps={{ "type": "email" }} label="E-Mail" variant="outlined" required />
          <MUI.TextField value={password} onChange={(e) => setPassword(e.target.value)} type="password" label="Passwort" required />
          <MUI.Button className="mt-2" variant="contained" type="submit">Anmelden</MUI.Button>
        </form>
      }
      {isAuthenticated &&
        <div>
          <h2>User</h2>
          <h3>Aktivität</h3>
          <p>
            {users.filter(user => new Date(user.last_activity) > new Date(Date.now() - 1000 * 60 * 20)).length} von {users.length} Kunden sind aktiv.
          </p>
          <p>
            Aktive Kunden IDs: {users.filter(user => new Date(user.last_activity) > new Date(Date.now() - 1000 * 60 * 20)).map(user => user._id).join(', ')}
          </p>
          <div className="users">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'index'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('index')}
                      >
                        Pos
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === '_id'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('_id')}
                      >
                        id
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'mail'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('mail')}
                      >
                        mail
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'firstname'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('firstname')}
                      >
                        Vorname
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'lastname'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('lastname')}
                      >
                        Nachname
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'registration_date'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('registration_date')}
                      >
                        Registrierungsdatum
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'last_activity'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('last_activity')}
                      >
                        Letzte Aktivität
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'role'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('role')}
                      >
                        Rolle
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'online'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('online')}
                      >
                        Online
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'phone'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('phone')}
                      >
                        Telefon
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'activated'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('activated')}
                      >
                        Aktiviert ({users.filter(user => user.activated).length}/{users.length})
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'paid'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('paid')}
                      >
                        Bezahlt ({users.filter(user => user.paid).length}/{users.length})
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Aktionen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedUsers.map((user, index) => (
                    <TableRow key={user._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{user._id}</TableCell>
                      <TableCell>{user.mail}</TableCell>
                      <TableCell>{user.firstname}</TableCell>
                      <TableCell>{user.lastname}</TableCell>
                      <TableCell>{user.registration_date}</TableCell>
                      <TableCell>{user.last_activity}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.online ? "Ja" : "Nein"}</TableCell>
                      <TableCell>{user.phone}</TableCell>
                      <TableCell>{user.activated ? "Ja" : "Nein"}</TableCell>
                      <TableCell>{user.paid ? "Ja" : "Nein"}</TableCell>
                      <TableCell>
                        <UserMenu user={user} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      }

    </div>
  );
}
